import {
  BaseService,
  Status,
  DataResponse,
} from '@/core/services/base.service';
import { User, UserModel } from '@/models/user';

interface ProfileResponse {
  status: boolean;
  data?: User;
}

class UserService extends BaseService {
  private url = '/api/user';

  constructor() {
    super();
  }

  public async profile(): Promise<ProfileResponse> {
    const res = await this.$http.get<UserModel>('/api/profile');
    return {
      status: res.status === Status.Ok,
      data: res.status === Status.Ok ? new User(res.data) : undefined,
    };
  }

  public async get(id: number): Promise<DataResponse<User>> {
    const res = await this.$http.get<UserModel>(`${this.url}/${id}`);
    return {
      status: res.status === Status.Ok,
      data: new User(res.data),
    };
  }

  public async put(user: User): Promise<DataResponse<User>> {
    const res = await this.$http.put<UserModel>(`${this.url}/${user.id}`, user);
    return {
      status: res.status === Status.Ok,
      data: new User(res.data),
    };
  }
}

export default new UserService();
