export interface VersionData {
  major: number;
  minor: number;
  patch: number;
}

export class Version {
  private major!: number;
  private minor!: number;
  private patch!: number;

  constructor(data: VersionData) {
    Object.assign(this, data);
  }

  get version(): string {
    return `${this.major}.${this.minor}.${this.patch}`;
  }
}
