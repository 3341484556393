<template>
  <svg
    class="svg-loading"
    height="100%"
    preserveAspectRatio="xMidYMid"
    viewBox="0 0 100 100"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(20 20)">
      <rect
        fill="#ecb387"
        height="30"
        transform="scale(1 1)"
        width="30"
        x="-15"
        y="-15"
      >
        <animateTransform
          attributeName="transform"
          begin="-0.32000000000000006s"
          calcMode="spline"
          dur="0.8s"
          keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
          keyTimes="0;0.2;0.5;0.8;1"
          repeatCount="indefinite"
          type="scale"
          values="1;1;0.2;1;1"
        />
      </rect>
    </g>
    <g transform="translate(50 20)">
      <rect
        fill="#f18d41"
        height="30"
        transform="scale(1 1)"
        width="30"
        x="-15"
        y="-15"
      >
        <animateTransform
          attributeName="transform"
          begin="-0.24s"
          calcMode="spline"
          dur="0.8s"
          keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
          keyTimes="0;0.2;0.5;0.8;1"
          repeatCount="indefinite"
          type="scale"
          values="1;1;0.2;1;1"
        />
      </rect>
    </g>
    <g transform="translate(80 20)">
      <rect
        fill="#e66707"
        height="30"
        transform="scale(1 1)"
        width="30"
        x="-15"
        y="-15"
      >
        <animateTransform
          attributeName="transform"
          begin="-0.16000000000000003s"
          calcMode="spline"
          dur="0.8s"
          keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
          keyTimes="0;0.2;0.5;0.8;1"
          repeatCount="indefinite"
          type="scale"
          values="1;1;0.2;1;1"
        />
      </rect>
    </g>
    <g transform="translate(20 50)">
      <rect
        fill="#f18d41"
        height="30"
        transform="scale(1 1)"
        width="30"
        x="-15"
        y="-15"
      >
        <animateTransform
          attributeName="transform"
          begin="-0.24s"
          calcMode="spline"
          dur="0.8s"
          keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
          keyTimes="0;0.2;0.5;0.8;1"
          repeatCount="indefinite"
          type="scale"
          values="1;1;0.2;1;1"
        />
      </rect>
    </g>
    <g transform="translate(50 50)">
      <rect
        fill="#e66707"
        height="30"
        transform="scale(1 1)"
        width="30"
        x="-15"
        y="-15"
      >
        <animateTransform
          attributeName="transform"
          begin="-0.16000000000000003s"
          calcMode="spline"
          dur="0.8s"
          keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
          keyTimes="0;0.2;0.5;0.8;1"
          repeatCount="indefinite"
          type="scale"
          values="1;1;0.2;1;1"
        />
      </rect>
    </g>
    <g transform="translate(80 50)">
      <rect
        fill="#c05300"
        height="30"
        transform="scale(1 1)"
        width="30"
        x="-15"
        y="-15"
      >
        <animateTransform
          attributeName="transform"
          begin="-0.08000000000000002s"
          calcMode="spline"
          dur="0.8s"
          keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
          keyTimes="0;0.2;0.5;0.8;1"
          repeatCount="indefinite"
          type="scale"
          values="1;1;0.2;1;1"
        />
      </rect>
    </g>
    <g transform="translate(20 80)">
      <rect
        fill="#e66707"
        height="30"
        transform="scale(1 1)"
        width="30"
        x="-15"
        y="-15"
      >
        <animateTransform
          attributeName="transform"
          begin="-0.16000000000000003s"
          calcMode="spline"
          dur="0.8s"
          keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
          keyTimes="0;0.2;0.5;0.8;1"
          repeatCount="indefinite"
          type="scale"
          values="1;1;0.2;1;1"
        />
      </rect>
    </g>
    <g transform="translate(50 80)">
      <rect
        fill="#c05300"
        height="30"
        transform="scale(1 1)"
        width="30"
        x="-15"
        y="-15"
      >
        <animateTransform
          attributeName="transform"
          begin="-0.08000000000000002s"
          calcMode="spline"
          dur="0.8s"
          keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
          keyTimes="0;0.2;0.5;0.8;1"
          repeatCount="indefinite"
          type="scale"
          values="1;1;0.2;1;1"
        />
      </rect>
    </g>
    <g transform="translate(80 80)">
      <rect
        fill="#8e3e02"
        height="30"
        transform="scale(0.818385 0.818385)"
        width="30"
        x="-15"
        y="-15"
      >
        <animateTransform
          attributeName="transform"
          begin="0s"
          calcMode="spline"
          dur="0.8s"
          keySplines="0.5 0.5 0.5 0.5;0 0.1 0.9 1;0.1 0 1 0.9;0.5 0.5 0.5 0.5"
          keyTimes="0;0.2;0.5;0.8;1"
          repeatCount="indefinite"
          type="scale"
          values="1;1;0.2;1;1"
        />
      </rect>
    </g>
  </svg>
</template>
