import {
  BaseService,
  Status,
  DataResponse,
} from '@/core/services/base.service';
import { Result } from '@/models/results.model';

class SearchService extends BaseService {
  private url = '/api/search';

  constructor() {
    super();
  }

  public async search(value: string): Promise<DataResponse<Result<any>[]>> {
    const res = await this.$http.get<Result<any>[]>(
      `${this.url}?search=${value}`,
    );
    return {
      status: res.status === Status.Ok,
      data: res.data,
    };
  }
}

export default new SearchService();
